import { React } from "react";
import TerminalHeader from '../elements/terminal_header';
import SidebarMenu from '../elements/sidebar'
import {useParams, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import { loginRequest } from "../config/authConfig";
import { useMsal} from '@azure/msal-react';
import Loader from '../elements/loader';
import Table from '../elements/table';
import Modal from '../elements/modal';
import PreLoadModel from '../models/preLoadModel'
import { Plus, PlusCircleFill, Search, Trash } from 'react-bootstrap-icons';
import { toast, ToastContainer } from 'react-toastify';
import Tab from '../elements/tab';
import WarningLine from '../elements/warning_line';
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import API from "../services/api";


const clientTerminal = (new API()).api;

function PreLoad() {
  let navigate = useNavigate();

  let [seed, setSeed]= useState(1)
  let [stateTerminal, setTerminal] = useState({bay: []});
  let [stateProducts, setProducts] = useState();
  let [stateSelectedProducts, setSelectedProducts] = useState([]);
  let [stateOnLoading, setLoading] = useState();
  let [statePreLoadModel, setPreLoadModel] = useState(PreLoadModel);
  let [statePreLoadModelWithId, setPreLoadModelWithId] = useState(PreLoadModel);
  let [stateCarriers, setCarriers] = useState([]);
  let [stateDrivers, setDrivers] = useState([]);
  let [stateSuppliers, setSuppliers] = useState([]);
  let [stateVehicles, setVehicles] = useState([]);
  let [stateCustomers, setCustomers] = useState([]);
  let [stateAccounts, setAccounts] = useState([]);
  let [stateDestinations, setDestinations] = useState([]);
  let [stateValidations, setValidations] = useState({warnings: [], errors: []});
  let [stateBlockVehicleOptions, setBlockVehicleOptions] = useState(false);
  let [showProducts, setShowProducts] = useState(false);
  let [loadProducts, setLoadProducts] = useState(false);
  let [showResult, setShowResult] = useState(false);
  let [stateSelectedDriver, setSelectedDriver] = useState({});

  let [currentUser, setCurrentUser] = useState()
  let {termId} = useParams();
  let tableHeader = ["ID", "Product Description", "Density/Gravity", "Disruption", "Available"];
  let tableValues = ["id", "description", "gravity", "disruptionStatus", "availableResponseByCustomerAndAccount"];
  var [stateIsAdmin, setIsAdmin] = useState(true);
  
  const { instance, accounts } = useMsal();

  const initialState = {
    termId: "",
    carrier: "",
    driver: "",
    vehicle: [],
    vehicleXRef: "",
    supplier: "",
    customer: "",
    account: "",
    destination: "",
    product: [],
    accountProduct: "",
    tank: "",
    arrival_date: "",
  }
  const initialStateObj ={
    termId: [],
    carrier: [],
    driver: [],
    vehicle: [],
    vehicleXRef: [],
    supplier: [],
    customer: [],
    account: [],
    destination: [],
    product: [],
    accountProduct: [],
    tank: [],
    arrival_date: [],
  }

  const cleanForm = () => {
    setSeed(Math.random())
    setPreLoadModel(initialState)
    getPreLoadInformation()
    setSelectedProducts([])
    setPreLoadModelWithId(initialStateObj)
    setBlockVehicleOptions(false)
    setShowResult(false)
    setLoadProducts(false);
  }

  const getTerminalInformation = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      setLoading(true);
      clientTerminal.get('Terminal/Get?termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        if(response.status != 200) {
          return navigate("/error");
        }
        setTerminal(response.data);
      });
      return true
    }).catch((e) => {
      return false
    });
  }

  const getCarrierInformation = (driverNo) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Carrier/GetByDriverNoAndTermId?driverNo=' + driverNo + '&termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        let carriers = response.data.map( dt => { return {label: dt.carrNo + " - " + dt.name, value: dt.carrNo} })
        setCarriers(carriers);
        if(carriers.length == 1) {
          let model = statePreLoadModel;
          model["carrier"] = carriers[0].value;
          setPreLoadModel(model);
          getVehicleInformation(carriers[0].value);
        }
      });
    });
  }

  const getCarriersInformation = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Carrier/GetByTermId?termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        let carriers = response.data.map( dt => { return {label: dt.carrNo + " - " + dt.name, value: dt.carrNo} })
        setCarriers(carriers);
        if(carriers.length == 1) {
          let model = statePreLoadModel;
          model["carrier"] = carriers[0].value;
          setPreLoadModel(model);
          getVehicleInformation(carriers[0].value);
        }
      });
    });
  }

  const getDriversInformation = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Driver/GetByTermId?termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        let drivers = response.data.map( dt => { return {label: parseInt(dt.driverNo, 10).toString() + " - " + dt.name, value: dt.driverNo} })
        setDrivers(drivers)});
    });
  }

  const getDriverInformation = (carrier) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Driver/GetByCarrierNoAndTermId?termId=' + termId + '&carrierNo=' + carrier, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        let drivers = response.data.map( d => { return {label: parseInt(d.driverNo, 10).toString() + " - " + d.name, value: d.driverNo} })
        
        if(drivers.length == 1) {
          let model = statePreLoadModel;
          model["driver"] = drivers[0].value;
          setPreLoadModel(model);
          setDrivers(drivers[0].value);
        }
        setDrivers(drivers);
      });
    })
  }

  const getVehicleInformation = (carrier) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Vehicle/GetByCarrierNoAndTermId?termId=' + termId + '&carrierNo=' + statePreLoadModel["carrier"], {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        let vehicles = response.data.map( dt => { return {label: dt.vehicleId, value: dt.vehicleId} })
        setVehicles(vehicles);
      });
    })
  }


  const getPreLoadInformation = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {

      clientTerminal.get('me/IsAdminOrSupport', {headers: {"Access-Control-Allow-Origin": "*", "Authorization" : "Bearer " + response.idToken}}).then((response_admin) => {
        setIsAdmin(response_admin.data);
        if (response_admin.data) {
          getCarriersInformation();
          getDriversInformation();
          clientTerminal.get('Supplier/GetByTermId?termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response1) => {
            let suppliers = response1.data.map( dt => { return {label: parseInt(dt.supplierNo, 10).toString() + " - " + dt.shortSupplierName, value: dt.supplierNo} })
            setSuppliers(suppliers);
          });
          setLoading(false);
          return true;
        } else {
          clientTerminal.get('me', {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
            let model = statePreLoadModel
    
            setCurrentUser(response.data);
            
            if(response.data.profile != "carrier") {
              getCarrierInformation(response.data.profileId);
            } else {
              setCarriers([{label: response.data.profileId + " - " + response.data.profileName, value: response.data.profileId}]);
              let model = statePreLoadModel
              model["carrier"] = response.data.profileId
              setPreLoadModel(model) 
              getVehicleInformation(response.data.profileId)
            }
    
            if(response.data.profile != "driver") {
              if(response.data.profile == "carrier"){
                getDriverInformation(response.data.profileId);
              } else { 
                getDriversInformation();
              }
            } else {
              setDrivers([{label: parseInt(response.data.profileId, 10).toString() + " - " + response.data.profileName, value: response.data.profileId}]);
              let model = statePreLoadModel
              model["driver"] = response.data.profileId
              setPreLoadModel(model) 
            }
    
            if(response.data.profile == "customer") {
              setCustomers([{label: parseInt(response.data.profileName, 10).toString(), value: response.data.profileId}]);
            }
    
          });

          clientTerminal.get('Supplier/GetByTermId?termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
            let suppliers = response.data.map( dt => { return {label: parseInt(dt.supplierNo, 10).toString() + " - " + dt.shortSupplierName, value: dt.supplierNo} })
            setSuppliers(suppliers);
          });
          setLoading(false);
        }
      });

      return true
    }).catch((e) => {
      return false
    });
  }

  const setProduct = (evt) => {
    setShowResult(false)
    let model = statePreLoadModel
    let value = evt.target.value;
    let name = "product";
    let allProducts = [];

    stateProducts.forEach(p => {
      allProducts.push(p)
    });

    let selectedProduct = allProducts.find((obj) => {
      return obj.id === value;
    });

    model[name] = model[name] === undefined ? [] : model[name];

    if(evt.target.checked) {
      model[name].push(value);
      stateSelectedProducts.push(selectedProduct);
      let pushSelected = stateSelectedProducts.slice();
      setSelectedProducts(pushSelected)
    } else {
      let productToRemove = stateSelectedProducts.findIndex((obj) => obj.id == value );
      stateSelectedProducts.splice(productToRemove, 1);
      let pushSelected = stateSelectedProducts.slice();
      setSelectedProducts(pushSelected)
      setShowResult(false)
    }
    setPreLoadModel(model)    
  }

  const setCarrier = (evt) => {
    if(evt.length != 0) {
      let model = statePreLoadModel
      model["carrier"] = evt[0].value
      setPreLoadModel(model)
      
      let model_with_id = statePreLoadModelWithId
      model_with_id["carrier"] = evt[0]
      setPreLoadModelWithId(model_with_id)
      
      getVehicleInformation(evt[0].value)

      if (stateIsAdmin) {
        const request = {
          ...loginRequest,
          account: accounts[0]
        };
    
        instance.acquireTokenSilent(request).then((response) => {
          clientTerminal.get('Driver/GetByCarrierNoAndTermId?termId=' + termId + '&carrierNo=' + evt[0].value, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
            let drivers = response.data.map( d => { return {label: parseInt(d.driverNo, 10).toString() + " - " + d.name, value: d.driverNo} })
            
            if(drivers.length == 1) {
              let model = statePreLoadModel;
              model["driver"] = drivers[0].value;
              setPreLoadModel(model);
              setDrivers(drivers[0].value);
            }
            setDrivers(drivers);
          });
        })
      }

        setBlockVehicleOptions(false)
    } else {
      cleanForm();
      setLoading(true)
      let model = statePreLoadModel
      model["carrier"] = ""
      model["driver"] = ""
      model["supplier"] = ""
      setPreLoadModel(model)

      let model_with_id = statePreLoadModelWithId
      model_with_id["carrier"] = []
      model_with_id["driver"] = []
      model_with_id["vehicle"] = []
      model_with_id["supplier"] = []
      setPreLoadModelWithId(model_with_id)
      
      setVehicles([])
      setDrivers([]);
      setBlockVehicleOptions(false)
      setSelectedProducts([])
      setShowResult(false)
      getPreLoadInformation();
    }
    setSeed(Math.random())
  }

  const getDriverFullInformation = (driverNo) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Driver/GetByDriverNoAndTermId?driverNo=' + driverNo + '&termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        setSelectedDriver(response.data)});
    });
  }

  const setDriver = (evt) => {
    if(evt.length != 0) {
      let model = statePreLoadModel
      model["driver"] = evt[0].value
      setPreLoadModel(model) 

      let model_with_id = statePreLoadModelWithId
      model_with_id["driver"] = evt[0]
      setPreLoadModelWithId(model_with_id)
      
      getDriverFullInformation(evt[0].value)
      getCarrierInformation(evt[0].value)
    } else {
      let model = statePreLoadModel
      model["driver"] = ""
      setPreLoadModel(model)

      let model_with_id = statePreLoadModelWithId
      model_with_id["driver"] = []
      setPreLoadModelWithId(model_with_id)
      setSelectedProducts([])
      setShowResult(false)
      setShowProducts(false)
      cleanForm();
    }
    setSeed(Math.random())
  }

  const setSupplier = (evt) => {
    if(evt.length != 0) {
    let model = statePreLoadModel
    model["supplier"] = evt[0].value
    model["customer"] = ""
    model["account"] = ""
    model["destination"] = ""
    setPreLoadModel(model)

    let model_with_id = statePreLoadModelWithId
    model_with_id["supplier"] = evt[0]
    model_with_id["customer"] = []
    model_with_id["account"] = []
    model_with_id["destination"] = []
    setPreLoadModelWithId(model_with_id)

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    if(currentUser != "customer") {
      instance.acquireTokenSilent(request).then((response) => {
          clientTerminal.get('Customer/GetByCarrierNoAndSupplierNoAndTermId?supplierNo=' + statePreLoadModel["supplier"] + '&carrierNo=' + statePreLoadModel["carrier"] + '&termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
          let customers = response.data.map( dt => { if (dt != null) {return {label: parseInt(dt.custNo, 10).toString() + " - " + dt.custName, value: dt.custNo}}  }).filter(n => n)
          setCustomers(customers);
        });
      })
    }
  } else {
    let model = statePreLoadModel
    model["supplier"] = ""
    model["customer"] = ""
    model["account"] = ""
    model["destination"] = ""
    setPreLoadModel(model) 
    
    let model_with_id = statePreLoadModelWithId
    model_with_id["supplier"] = []
    model_with_id["customer"] = []
    model_with_id["account"] = []
    model_with_id["destination"] = []
    setPreLoadModelWithId(model_with_id)
    
    setCustomers([]);
    setAccounts([]);
    setDestinations([]);
    setSelectedProducts([])
    setShowResult(false)

  }
  setSeed(Math.random())
}

  const setVehicle = (evt) => {
    let model = statePreLoadModel
    model["vehicle"] = evt.map(e => e.label)
    setPreLoadModel(model)

    let model_with_id = statePreLoadModelWithId
    model_with_id["vehicle"] = evt.map(e => e)
    setPreLoadModelWithId(model_with_id)

    if(evt.length >= 2) {
      setBlockVehicleOptions(true)
    } else {
      setBlockVehicleOptions(false)
    }
    
    setSeed(Math.random())

  }

  const setCustomer = (evt) => {
    if(evt.length != 0) {
    let model = statePreLoadModel
    model["customer"] = evt[0].value
    setPreLoadModel(model)

    let model_with_id = statePreLoadModelWithId
    model_with_id["customer"] = evt[0]
    setPreLoadModelWithId(model_with_id)

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Account/GetBySupplierNoAndCustomerNoAndTermId?supplierNo=' + statePreLoadModel["supplier"] + '&custNo=' + statePreLoadModel["customer"] + '&termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        let accounts = response.data.map( dt => { return {label: parseInt(dt.acctNo, 10).toString() + " - " + dt.acctName, value: dt.acctNo} })
        if(accounts.length == 1) {
          let model = statePreLoadModel;
          model["account"] = accounts[0].value;
          setPreLoadModel(model);
          setAccounts(accounts[0].value);

          instance.acquireTokenSilent(request).then((response) => {
            clientTerminal.get('Destination/GetByCustomerNoAccountNoSupplierNoTermId?supplierNo=' + statePreLoadModel["supplier"] + '&customerNo=' + statePreLoadModel["customer"] + '&accountNo=' + statePreLoadModel["account"] + '&carrierNo=' + statePreLoadModel["carrier"] + '&termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
              let destinations = response.data.map( dt => { return {label: parseInt(dt.destinationNo, 10).toString() + " - " + dt.destinationName, value: dt.destinationNo} })
              if(destinations.length == 1) {
                let model = statePreLoadModel;
                model["destination"] = destinations[0].value;
                setPreLoadModel(model);
                setDestinations(destinations[0].value);
              }
              setDestinations(destinations);
            });
          })
        }
        setAccounts(accounts);
      });
    })
  } else {
    let model = statePreLoadModel
    model["customer"] = ""
    model["account"] = ""
    model["destination"] = ""
    setPreLoadModel(model) 

    let model_with_id = statePreLoadModelWithId
    model_with_id["customer"] = []
    model_with_id["account"] = []
    model_with_id["destination"] = []
    setPreLoadModelWithId(model_with_id)
    setAccounts([]);
    setDestinations([]);
    setSelectedProducts([])
    setShowResult(false)
  }
  setSeed(Math.random())
}

  const setAccount = (evt) => {
    if(evt.length != 0) {
      let model = statePreLoadModel
      model["account"] = evt[0].value
      setPreLoadModel(model)

      let model_with_id = statePreLoadModelWithId
      model_with_id["account"] = evt[0]
      setPreLoadModelWithId(model_with_id)

      const request = {
        ...loginRequest,
        account: accounts[0]
      };

      instance.acquireTokenSilent(request).then((response) => {
        clientTerminal.get('Destination/GetByCustomerNoAccountNoSupplierNoTermId?supplierNo=' + statePreLoadModel["supplier"] + '&customerNo=' + statePreLoadModel["customer"] + '&accountNo=' + statePreLoadModel["account"] + '&carrierNo=' + statePreLoadModel["carrier"] + '&termId=' + termId, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
          let destinations = response.data.map( dt => { return {label: parseInt(dt.destinationNo, 10).toString() + " - " + dt.destinationName, value: dt.destinationNo} })
          if(destinations.length == 1) {
            let model = statePreLoadModel;
            model["destination"] = destinations[0].value;
            setPreLoadModel(model);
            setDestinations(destinations[0].value);
          }
          setDestinations(destinations);
        });

        setLoadProducts(true);
        clientTerminal.get('Web/GetTerminalProductByCustomerAndAccountAndSupplierAndDestinationAndDriver?PlantCode=' + termId + '&acctNo=' + model['account'] + '&custNo=' + model['customer'] + '&supplierNo=' + model['supplier']+ '&destinationNo=null'+ '&driverNo=' + stateSelectedDriver.driverNo, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
          setProducts(response.data);
          setShowProducts(true)
          })
        });
    } 
    else 
    {
      let model = statePreLoadModel
      model["account"] = ""
      model["destination"] = ""
      model["product"] = []
      setPreLoadModel(model) 

      let model_with_id = statePreLoadModelWithId
      model_with_id["account"] = []
      model_with_id["destination"] = []
      model_with_id["product"] = []
      setPreLoadModelWithId(model_with_id)
      setProducts([])
      setDestinations([]);
      setSelectedProducts([])
      setLoadProducts(false);
      setShowProducts(false);
      setShowResult(false)

    }
  setSeed(Math.random())

}

  const setDestination = (evt) => {
    setShowProducts(false)
    if(evt.length != 0) {
    setShowProducts(false);
    let model = statePreLoadModel
    model["destination"] = evt[0].value
    setPreLoadModel(model)

    let model_with_id = statePreLoadModelWithId
    model_with_id["destination"] = evt[0]
    setPreLoadModelWithId(model_with_id)

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    setLoadProducts(true);
    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Web/GetTerminalProductByCustomerAndAccountAndSupplierAndDestinationAndDriver?PlantCode=' + termId + '&acctNo=' + model['account'] + '&custNo=' + model['customer'] + '&supplierNo=' + model['supplier']+ '&destinationNo=' + model['destination']+ '&driverNo=' + stateSelectedDriver.driverNo, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
        setProducts(response.data);
        setShowProducts(true)
        })
    })

    } else {
      let model = statePreLoadModel
      model["destination"] = ""
      setPreLoadModel(model) 
      let model_with_id = statePreLoadModelWithId
      model_with_id["destination"] = []
      model_with_id["product"] = []
      setPreLoadModelWithId(model_with_id)
      setProducts([])
      setSelectedProducts([])
      setShowResult(false)
      setLoadProducts(false);
    }
    setSeed(Math.random())
  }

  const sendForm = () => {
    toast('Searching for load...', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      var form = statePreLoadModel;
      form["termId"] = termId
      form["product"] = stateSelectedProducts.map(p => { return p.id})

    clientTerminal.post('Validation/ValidateCannotLoad', form, {headers: {"Authorization" : "Bearer " + response.idToken}}).then((response) => {
      if(response.data.status == "success") {      
          if (stateSelectedProducts.map(prod => {return prod.expired}).includes(true)) {
            toast.warning('At least one product have expired.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
          } else {
            toast.success('Based on your search, you are able to load.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
          }

          setShowResult(true);
      } else if(response.data.status == "error") {
        toast.error('It is impossible to load. Please verify the actions required.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
          setShowResult(true);
        }
      setValidations(response.data.data)
    }, (error) => { toast.error('Error on get information. Please try again later.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      }); 
      setShowResult(false);
      setShowSuccessResult(false);
    });
  })
  }

  useEffect(() => { getTerminalInformation(); getPreLoadInformation()}, [])

  console.log(statePreLoadModel)
  console.log(statePreLoadModelWithId)

  return (
    <>
    {
      stateOnLoading ? <Loader /> :
      <> 
        <TerminalHeader name={stateTerminal.name} address={stateTerminal.addr1}/>
        <div className="container">
        <div className="em-l em-l--two-column-sticky">
          <div className="em-l__secondary"><div className="fpo-block"><SidebarMenu termId={termId} /></div></div>
          <div className="em-l__main">
            <div className="container">
                <div className="row">
                  <form className="em-l-grid em-l-grid--1-to-3up" name={name} id={name}>
                  <div className="em-l-grid__item"><div className="fpo-block">
                    <div className="em-c-field em-u-max-width-30  ">
                    <label htmlFor="select" className="em-c-field__label">
                      {"Carrier"}
                    </label>
                    <div className="em-c-field__body">
                      <Select
                        closeMenuOnSelect={true}
                        isMulti={true}
                        options={stateCarriers}
                        onChange={setCarrier}
                        value={statePreLoadModelWithId["carrier"]}
                      />
                    </div>
                  </div>  
                  </div></div>    
                  <div className="em-l-grid__item"><div className="fpo-block">
                  <div className="em-c-field em-u-max-width-30  ">
                  <label htmlFor="select" className="em-c-field__label">
                    {"Driver"}
                  </label>
                  <div className="em-c-field__body">
                    <Select
                      closeMenuOnSelect={true}
                      isMulti={true}
                      options={stateDrivers}
                      onChange={setDriver}
                      value={statePreLoadModelWithId["driver"]}
                    />
                  </div>
                </div>  
                  </div></div>    
                  <div className="em-l-grid__item"><div className="fpo-block">
                  <div className="em-c-field em-u-max-width-30  ">
                  <label htmlFor="select" className="em-c-field__label">
                    {"Supplier"}
                  </label>
                  <div className="em-c-field__body">
                    <Select
                      closeMenuOnSelect={true}
                      isMulti={true}
                      options={stateSuppliers}
                      onChange={setSupplier}
                      value={statePreLoadModelWithId["supplier"]}
                    />
                  </div>
                </div>
                  </div></div>    
                  <div className="em-l-grid__item"><div className="fpo-block">
                  <div className="em-c-field em-u-max-width-30  ">
                  <label htmlFor="select" className="em-c-field__label">
                    {"Vehicle"}
                  </label>
                  <div className="em-c-field__body">
                    <Select
                      closeMenuOnSelect={false}
                      isMulti={true}
                      options={stateBlockVehicleOptions ? [] : stateVehicles}
                      onChange={setVehicle}
                      value={statePreLoadModelWithId["vehicle"]}
                    />
                  </div>
                </div>  
                  </div></div>    
                  <div className="em-l-grid__item"><div className="fpo-block">
                  <div className="em-c-field em-u-max-width-30  ">
                  <label htmlFor="select" className="em-c-field__label">
                    {"Customer"}
                  </label>
                  <div className="em-c-field__body">
                    <Select
                      closeMenuOnSelect={true}
                      isMulti={true}
                      options={stateCustomers}
                      onChange={setCustomer}
                      value={statePreLoadModelWithId["customer"]}
                    />
                  </div>
                </div>  
                  </div></div>    
                  <div className="em-l-grid__item"><div className="fpo-block">
                  <div className="em-c-field em-u-max-width-30  ">
                  <label htmlFor="select" className="em-c-field__label">
                    {"Account"}
                  </label>
                  <div className="em-c-field__body">
                    <Select
                      closeMenuOnSelect={true}
                      isMulti={true}
                      options={stateAccounts}
                      onChange={setAccount}
                      value={statePreLoadModelWithId["account"]}
                    />
                  </div>
                </div>
                </div></div>    
                  <div className="em-l-grid__item"><div className="fpo-block">
                  <div className="em-c-field em-u-max-width-30  ">
                  <label htmlFor="select" className="em-c-field__label">
                    {"Destination"}
                  </label>
                  <div className="em-c-field__body">
                    <Select
                      closeMenuOnSelect={true}
                      isMulti={true}
                      options={stateDestinations}
                      onChange={setDestination}
                      value={statePreLoadModelWithId["destination"]}
                    />
                  </div>
                </div></div></div>    
                  </form>
                  {
                    loadProducts ?
                    <>
                  {
                    showProducts ?
                    <>
                    <label>
                      <Modal 
                        type="products"
                        buttonOpenModal={
                          <button className="em-c-btn  em-c-btn--big em-js-btn-selectable select-products">
                          <span className="em-c-btn__text"><PlusCircleFill/> Select Products</span>
                          </button>
                        }
                          title="Product List" 
                          description="Please, select the products you intend to load:" 
                          content={
                            [
                              <Table 
                              type="selectable"
                              header={tableHeader} 
                              content={stateProducts} 
                              values={tableValues}
                              selectedContent={stateSelectedProducts}
                              selectFunc={setProduct}/>
                            ]
                          }
                      />
                    </label>
                    <Table header={tableHeader} content={stateSelectedProducts} values={tableValues}/>
                    </>
                    :
                    <div className="loader-area-limiter"><Loader/></div>
                  }
                  </>
                  :
                    <></>
                  }
                </div>
              <div className="row">
                <div className="em-c-btn-bar">
                  <ul className="em-c-btn-bar__list">
                    { stateSelectedProducts.length != 0 ?
                      <>
                    <li className="em-c-btn-bar__item">
                      <button className="em-c-btn em-c-btn--primary em-c-btn--big em-js-btn-selectable"  onClick={sendForm}>
                        <span className="em-c-btn__text"><Search/> Search</span>
                      </button>
                    </li>
                    </>
                    :
                    <></>
                    }
                    <li className="em-c-btn-bar__item">
                      <button className="em-c-btn  em-c-btn--big em-js-btn-selectable" onClick={cleanForm}>
                        <span className="em-c-btn__text"><Trash /> Clear</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <br/>
              {
                showResult ? 
                <div className="row">
                  <Tab tabs={[{title: "Load Result", id:"success"}, {title: "Action Required", id:"errors"}, {title: "Warnings", id:"warning"}]} content={[
                  {
                    tabId: "success",
                    data: stateValidations.errors.length > 0 ? "You have to check the Action Required before continue." : stateSelectedProducts.map(prod => {return (
                      prod.expired ? 
                        <><p className="successResult">The product <b>{prod.id + " - " + prod.description}</b> is <b>expired</b>.</p></> 
                        : 
                        prod.availableByCloudZone ?
                          prod.bays.length > 1 ?
                            <><p className="successResult">The product <b>{prod.id + " - " + prod.description}</b> is in the bays <b>{prod.bays.map(bay => {return(bay + ", ")})}</b></p></>
                            :
                            <><p className="successResult">The product <b>{prod.id + " - " + prod.description}</b> is in the bay <b>{prod.bays.map(bay => {return (bay + ".")})}</b></p></>
                          :
                          <><p className="successResult">The product <b>{prod.id + " - " + prod.description}</b> cannot be loaded.  <b>No CZ designated</b>.</p></> )})                        
                  },
                  {
                    tabId: "errors",
                    data: stateValidations.errors.map(validation => {return(<WarningLine title={validation.split("-")[0]} description={validation.split("-")[3]} resolution={validation.split("-")[4]} />) })
                  },
                  {tabId: "warning", 
                  data: stateValidations.warnings.map(validation => {return(<WarningLine title={validation.split("-")[0] + " - " + validation.split("-")[1]} description={validation.split("-")[3]} resolution={validation.split("-")[4]} />) })}]}/>
                </div>
                : 
                <></>
              }
            </div>
          </div>
        </div>
        </div>
        <ToastContainer/>
      </>
    }
    
    </>
  );
}
 
export default PreLoad;