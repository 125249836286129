import React from 'react';
import { Oval } from "react-loader-spinner";

const Loader = () => {
    return (
        <>
        <div
            className="em-c-loader loader-area"
            role="alert"
            aria-live="assertive"
            aria-busy="true"
            aria-label="Loading, please wait"
            >
                <Oval
                    visible={true}
                    height="90"
                    width="90"
                    color="#3190d9"
                    secondaryColor="#fff"
                    strokeWidth = "4"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
        </div>
        </>
    );
}

export default Loader;